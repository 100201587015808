import { lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import MainLayout from "../layouts/main";

//-----------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            top: 0,
            left: 0,
            width: 1,
            zIndex: 9999,
            position: "fixed",
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

//-----------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [{ path: "login", element: <Login /> }],
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "",
          element: <HomePage />,
        },
        {
          path: "product-filter",
          element: <ProductFilter />,
        },
        {
          path: "product-detail",
          element: <ProductDetail />,
        },
        {
          path: "post-detail",
          element: <PostDetail />,
        },
      ],
    },
    {
      path: "dashboard",
      element: <DashboardLayout />,
      children: [
        {
          path: "change-pwd",
          element: <ChangePassword />,
        },
        {
          path: "posts",
          element: <Post />,
        },
        {
          path: "new-post",
          element: <PostNew />,
        },
        {
          path: "post-detail",
          element: <PostDetail />,
        },
        {
          path: "menu",
          element: <Menu />,
        },
        {
          path: "users",
          element: <UserList />,
        },
        {
          path: "product-category",
          element: <ProductCategoryList />,
        },
        {
          path: "banner",
          element: <Banner />,
        },
        {
          path: "property-group",
          element: <PropertyGroupList />,
        },
        {
          path: "property-option",
          element: <PropertyOptionList />,
        },
        {
          path: "product",
          element: <Product />,
        },
        {
          path: "new-product",
          element: <ProductNew />,
        },
        {
          path: "setting",
          element: <Settings />,
        },
      ],
    },
  ]);
}

const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const ChangePassword = Loadable(
  lazy(() => import("../pages/authentication/ChangePassword"))
);
//-------------------------------------------------------------------------------------

const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));

//-------------------------------------------------------------------------------------
const Post = Loadable(lazy(() => import("../pages/dashboard/Post")));
const PostNew = Loadable(lazy(() => import("../pages/dashboard/PostNew")));

const Menu = Loadable(lazy(() => import("../pages/dashboard/Menu")));

const UserList = Loadable(lazy(() => import("../pages/dashboard/UserList")));

const ProductCategoryList = Loadable(
  lazy(() => import("../pages/dashboard/ProductCategory"))
);

const PropertyGroupList = Loadable(
  lazy(() => import("../pages/dashboard/PropertyGroup"))
);

const PropertyOptionList = Loadable(
  lazy(() => import("../pages/dashboard/PropertyOption"))
);

const Settings = Loadable(lazy(() => import("../pages/dashboard/Settings")));

const Banner = Loadable(lazy(() => import("../pages/dashboard/Banner")));
const Product = Loadable(lazy(() => import("../pages/dashboard/Product")));
const ProductNew = Loadable(
  lazy(() => import("../pages/dashboard/ProductNew"))
);

//------------------------------------------------------------------------------------

const PostDetail = Loadable(
  lazy(() => import("../pages/HomePage/Post/PostDetail"))
);

const HomePage = Loadable(lazy(() => import("../pages/HomePage")));
const ProductDetail = Loadable(
  lazy(() => import("../pages/HomePage/Product/ProductDetail"))
);

const ProductFilter = Loadable(
  lazy(() => import("../pages/HomePage/Product/ProductFilter"))
);
