// export const baseURL = process.env.REACT_APP_BASE_URL;
export const baseURL = "https://api.tiemvanghaithoai.vn";//server
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const FRONT_URL =
  process.env.REACT_APP_GA_MEASUREMENT_ID || "http://localhost:3000";//FE
export const FAN_PAGE_FB = "https://www.facebook.com/tvhaithoai";
export const ZALO = "https://zalo.me/0939246998";
export const YOUTUBE = "https://www.youtube.com";
export const HOTLINE = "0939 246 998";
export const MESSENGER = "https://m.me/tvhaithoai";
export const FB_SHARE_LINK = "https://www.facebook.com/sharer/sharer.php?u=";
