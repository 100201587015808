import axios from "axios";
import Cookies from "js-cookie";
import { baseURL } from "../config";

const axiosClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + Cookies.get("x-access-token"),
  },
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  function async(config) {
    // Do something before request is sent
    config.headers = {
      Authorization: "Bearer " + Cookies.get("x-access-token"),
      Accept: "application/json",
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function async(error) {
    const { data } = error.response;
    console.log(error);
    throw new Error(data?.message);
  }
);

export default axiosClient;
