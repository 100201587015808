import "./App.css";
import NotistackProvider from "./components/NotistackProvider";
import Router from "./routers";
import ThemeConfig from "./theme";

//-----------------------------------------------------------------

function App() {
  console.warn = function () {};
  return (
    <ThemeConfig>
      <NotistackProvider>
        <Router />
      </NotistackProvider>
    </ThemeConfig>
  );
}

export default App;
