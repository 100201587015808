import { Icon } from "@iconify/react";
import {
  alpha,
  Box,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Scrollbar from "../../components/Scrollbar";
import Logo from "../../components/Logo";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import { toNested } from "../../_helper";
import { Stack } from "@mui/system";
import { PATH_PAGE } from "../../routers/paths";

//-----------------------------------------------------------------
const PADDING = 2.5;
const ITEM_SIZE = 48;

//-----------------------------------------------------------------
const ImgStyle = styled("img")(({ theme }) => ({
  width: "13rem",
  height: "13rem",
  [theme.breakpoints.down("md")]: {
    width: "10rem",
    height: "10rem",
  },
}));

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: ITEM_SIZE,
  textTransform: "capitalize",
  paddingLeft: theme.spacing(PADDING),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
}));
//-----------------------------------------------------------------
function MenuMobileItem({
  item,
  isOpen,
  isActive,
  menuConfig,
  setMenuConfigItem,
}) {
  const { id, value, name, children } = item;
  return (
    <>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <ListItemStyle
          to={value}
          component={RouterLink}
          sx={{
            ...(isActive && {
              color: "primary.main",
              fontWeight: "fontWeightMedium",
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.selectedOpacity
                ),
            }),
          }}
        >
          <ListItemText disableTypography primary={name} />
        </ListItemStyle>
        {!!children && (
          <IconButton
            onClick={() => {
              setMenuConfigItem(toNested(menuConfig, id));
            }}
          >
            <Box
              component={Icon}
              icon={isOpen ? arrowIosDownwardFill : arrowIosForwardFill}
              sx={{ width: 16, height: 16, mx: 2 }}
            />
          </IconButton>
        )}
      </Stack>
    </>
  );
}

//-----------------------------------------------------------------
export default function NavbarMobie({ menuConfig }) {
  const [menuConfigItem, setMenuConfigItem] = useState(
    toNested([...menuConfig])
  );
  const [mobileOpen, setMobileOpen] = useState(true);
  const [search, setSearch] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();
 
  useEffect(() => {
    if (mobileOpen) {
      handleDrawerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  useEffect(() => {
    (() => {
      setMenuConfigItem(toNested([...menuConfig]));
    })();
  }, [menuConfig]);

  return (
    <>
      <Box position="relative">
        <IconButton
          onClick={handleDrawerOpen}
          sx={{ position: "absolute", top: "40%" }}
        >
          <Icon icon="ic:round-menu-open" />
        </IconButton>
        <Box
          display="flex"
          justifyContent="center"
          sx={{ my: "-1.5rem" }}
          alignItems="center"
          component={RouterLink}
          to="/"
        >
          <ImgStyle src="/static/logo/logo.png" alt="logo" />
        </Box>
      </Box>

      <Box
        px={1}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <input
          style={{
            borderRadius: "5rem",
            height: "2.25rem",
            padding: "0 10px 0 10px",
            width: "100%",
            outline: "none",
            border: "2px solid rgba(145, 158, 171, 0.24)",
          }}
          placeholder="Tìm kiếm sản phẩm"
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter")
              navigate(`${PATH_PAGE.product_filter}?name=${search}`);
          }}
        />
        <IconButton
          onClick={() => {
            navigate(`${PATH_PAGE.product_filter}?name=${search}`);
          }}
          size="small"
          sx={{
            position: "absolute",
            right: 12,
          }}
        >
          <Icon icon="ic:baseline-search" />
        </IconButton>
      </Box>

      <Drawer
        open={mobileOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: 260 } }}
      >
        <Scrollbar>
          <Link component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
            <Logo
              sx={{ mx: PADDING, my: 3, width: 200 }}
              width={100}
              height={100}
            />
          </Link>
          <List disablePadding>
            {!!menuConfigItem[0]?.parent_id && menuConfig?.length > 0 && (
              <ListItemStyle
                onClick={() => {
                  const parent = menuConfig.filter(
                    (x) => x.id === menuConfigItem[0].parent_id
                  )[0];
                  setMenuConfigItem(toNested(menuConfig, parent.parent_id));
                }}
              >
                <ListItemIcon>
                  <Icon icon="material-symbols:arrow-back" />
                </ListItemIcon>

                <ListItemText disableTypography primary="Trở lại" />
              </ListItemStyle>
            )}
            {menuConfig?.length > 0 &&
              menuConfigItem.map((item, idx) => (
                <MenuMobileItem
                  item={item}
                  menuConfig={[...menuConfig]}
                  setMenuConfigItem={setMenuConfigItem}
                />
              ))}
          </List>
        </Scrollbar>
      </Drawer>
    </>
  );
}
