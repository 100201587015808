import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userAPI from "../API/userAPI";
import Cookies from "js-cookie";
import { randomIntFromInterval } from "../_helper";

export const login = createAsyncThunk("auth/login", async (payload) => {
  const _user = await userAPI.login(payload);
  Cookies.set("x-access-token", _user.data.access_token);
  Cookies.set("email", _user.data.user.email);
  Cookies.set("id", _user.data.user.id);
  Cookies.set("role", _user.data.user.role);
  return _user;
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    email: Cookies.get("email") || "",
    id: Cookies.get("id") || "",
    cover: `/static/avatar_${randomIntFromInterval(1, 20)}.jpg`,
    role: Cookies.get("role") || "",
  },
  reducers: {
    logout(state) {
      state.email = "";
      state.id = "";
      Cookies.remove("email");
      Cookies.remove("id");
      Cookies.remove("role");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.email = payload.data.user.email;
      state.id = payload.data.user.id;
      state.role = payload.data.user.role;
    });
  },
});
const { actions, reducer } = userSlice;
export const { logout } = actions;
export default reducer;
