import { baseURL } from "../config";
import axiosClient from "./axiosClient";

const userAPI = {
  login(data) {
    const url = `${baseURL}/api/login`;
    return axiosClient.post(url, data);
  },
  change_password(data) {
    const url = `${baseURL}/api/user/password`;
    return axiosClient.post(url, data);
  },
  create(data) {
    const url = `${baseURL}/api/users`;
    return axiosClient.post(url, data);
  },
  getAll(url) {
    return axiosClient.get(baseURL + url);
  },
  update(data, id) {
    const url = `${baseURL}/api/users/${id}`;
    return axiosClient.patch(url, data);
  },
  detele(data) {
    const url = `${baseURL}/api/users/bulk`;
    return axiosClient.delete(url, { data: { ids: data } });
  },
};

export default userAPI;
