// components
import SvgIconStyle from "../../components/SvgIconStyle";
import { PATH_DASHBOARD } from "../../routers/paths";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  user: getIcon("ic_user"),
  post_category: getIcon("ic_category"),
  change_pwd: getIcon("ic_password"),
  posts: getIcon("ic_blog"),
  menu: getIcon("ic_menu"),
};

const sidebarConfig = [
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: "Người dùng",
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
      },
      {
        title: "Banner",
        path: PATH_DASHBOARD.banner.root,
        icon: ICONS.menu,
      },
      {
        title: "Menu",
        path: PATH_DASHBOARD.menu.root,
        icon: ICONS.menu,
      },
      {
        title: "Loại sản phẩm",
        path: PATH_DASHBOARD.product_category.root,
        icon: ICONS.menu,
      },
      {
        title: "Nhóm tính chất",
        path: PATH_DASHBOARD.property_group.root,
        icon: ICONS.menu,
      },
      {
        title: "Lựa chọn tính chất",
        path: PATH_DASHBOARD.property_option.root,
        icon: ICONS.menu,
      },
      {
        title: "Sản phẩm",
        path: PATH_DASHBOARD.product.root,
        icon: ICONS.menu,
      },

      {
        title: "Cài đặt",
        path: PATH_DASHBOARD.setting.root,
        icon: ICONS.menu,
      },
      {
        title: "Đổi mật khẩu",
        path: PATH_DASHBOARD.change_pwd.root,
        icon: ICONS.change_pwd,
      },
      {
        title: "Bài viết",
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.posts,
      },
    ],
  },
];

export default sidebarConfig;
