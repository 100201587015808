//-----------------------------------------------------------------
function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
//-----------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
};

export const PATH_PAGE = {
  page404: "/404",
  page500: "/500",
  homePage: "/",
  productDetail: "/product-detail",
  postDetail: "/post-detail",
  product_filter: "/product-filter",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: {
    root: path(ROOTS_DASHBOARD, "/users"),
  },
  menu: {
    root: path(ROOTS_DASHBOARD, "/menu"),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, "/posts"),
    newPost: path(ROOTS_DASHBOARD, "/new-post"),
    detail: path(ROOTS_DASHBOARD, "/post-detail"),
  },
  change_pwd: {
    root: path(ROOTS_DASHBOARD, "/change-pwd"),
  },
  product_category: {
    root: path(ROOTS_DASHBOARD, "/product-category"),
  },
  property_group: {
    root: path(ROOTS_DASHBOARD, "/property-group"),
  },
  property_option: {
    root: path(ROOTS_DASHBOARD, "/property-option"),
  },
  banner: {
    root: path(ROOTS_DASHBOARD, "/banner"),
  },
  product: {
    root: path(ROOTS_DASHBOARD, "/product"),
    new: path(ROOTS_DASHBOARD, "/new-product"),
  },
  setting: {
    root: path(ROOTS_DASHBOARD, "/setting"),
  },
};
