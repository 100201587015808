import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Logo from "../../components/Logo";
import { Link as RouterLink } from "react-router-dom";
import { FAN_PAGE_FB, YOUTUBE, ZALO } from "../../config";

export default function Footer() {
  const hiddenUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <>
      <Divider
        sx={{
          width: "100%",
          margin: "1rem 0",
          borderBottomWidth: ".2rem",
        }}
      />
      <Box
        display="flex"
        justifyContent="center"
        my={4}
        component={RouterLink}
        to="/"
      >
        <Logo width={200} height={200} />
      </Box>
      <Box sx={{ backgroundColor: "#FFDFAE" }}>
        <Container>
          <Grid container spacing={4} py={2} px={4}>
            <Grid item xs={12} sm={4} md={4}>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    textTransform: "uppercase",
                    fontSize: hiddenUp
                      ? "1rem !important"
                      : ".875rem !important",
                  }}
                >
                  Liên hệ
                </Typography>
                <Divider sx={{ margin: "1rem 0" }} />
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: hiddenUp
                      ? "1rem !important"
                      : ".875rem !important",
                  }}
                >
                  Công ty TNHH vàng bạc đá quý Hai Thoài
                </Typography>
                <Typography
                  sx={{
                    fontSize: hiddenUp
                      ? "1rem !important"
                      : ".875rem !important",
                  }}
                >
                  Địa chỉ:{" "}
                  <b>Ấp Thị Tứ, thị trấn Một Ngàn, Châu Thành A, Hậu Giang</b>
                </Typography>
                <Stack direction="row">
                  <Typography
                    sx={{
                      fontSize: hiddenUp
                        ? "1rem !important"
                        : ".875rem !important",
                      mr: 1,
                    }}
                  >
                    Điện thoại:{" "}
                  </Typography>
                  <Typography>
                    <Typography
                      sx={{
                        textDecoration: "none",
                        color: "black",
                        fontWeight: "bold",
                        fontSize: hiddenUp
                          ? "1rem !important"
                          : ".875rem !important",
                      }}
                      component="a"
                      href="tel:02933946668"
                    >
                      02933 946 668
                    </Typography>
                    <br />
                    <Typography
                      sx={{
                        textDecoration: "none",
                        color: "black",
                        fontWeight: "bold",
                        fontSize: hiddenUp
                          ? "1rem !important"
                          : ".875rem !important",
                      }}
                      component="a"
                      href="tel:0939246996"
                    >
                      0939 246 996
                    </Typography>
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    fontSize: hiddenUp
                      ? "1rem !important"
                      : ".875rem !important",
                  }}
                >
                  Mã số doanh nghiệp: <b>6300224890</b>
                </Typography>
                <Typography
                  sx={{
                    fontSize: hiddenUp
                      ? "1rem !important"
                      : ".875rem !important",
                  }}
                >
                  Làm việc từ 7h đến 17h30 từ thứ hai đến thứ 7
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} spacing={2}>
              <Typography
                variant="h5"
                sx={{
                  textTransform: "uppercase",
                  fontSize: hiddenUp ? "1rem !important" : ".875rem !important",
                }}
              >
                Dịch vụ khách hàng
              </Typography>
              <Divider sx={{ margin: "1rem 0" }} />
              <Typography
                sx={{
                  fontSize: hiddenUp ? "1rem !important" : ".875rem !important",
                }}
              >
                Hướng dẫn đo size trang sức
              </Typography>
              <Typography
                sx={{
                  fontSize: hiddenUp ? "1rem !important" : ".875rem !important",
                }}
              >
                Cẩm nang về trang sức
              </Typography>
              <Typography
                sx={{
                  fontSize: hiddenUp ? "1rem !important" : ".875rem !important",
                }}
              >
                Câu hỏi thường gặp
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4} md={4} spacing={2}>
              <Typography
                variant="h5"
                sx={{
                  textTransform: "uppercase",
                  fontSize: hiddenUp ? "1rem !important" : ".875rem !important",
                }}
              >
                Kết nối với chúng tôi
              </Typography>
              <Divider sx={{ margin: "1rem 0" }} />
              <Stack direction="row" spacing={2}>
                <Avatar
                  component="a"
                  href={FAN_PAGE_FB}
                  target="_blank"
                  sx={{ width: 50, height: 50 }}
                  src="/static/icons/facebook.svg"
                />
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  src="/static/icons/zalo.png"
                  component="a"
                  href={ZALO}
                  target="_blank"
                />
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  src="/static/icons/youtube.svg"
                  component="a"
                  href={YOUTUBE}
                  target="_blank"
                />
              </Stack>
              {/* <img
                style={{ width: 200, marginTop: "1rem" }}
                src="/img/cert.png"
                alt="cert"
              /> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
