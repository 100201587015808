// hooks
import { useSelector } from "react-redux";
import MAvatar from "./MAvatar";
import { randomIntFromInterval } from "../_helper";
import createAvatar from "../utils/createAvatar";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const user = useSelector((state) => state.user);
  const cover = useSelector((state) => state.user?.cover);

  return (
    <MAvatar
      src={cover || `/static/avatar_${randomIntFromInterval(1, 20)}.jpg`}
      alt={user?.fullname}
      color={cover ? "default" : createAvatar("HT").color}
      {...other}
    >
      {createAvatar("HT")}
    </MAvatar>
  );
}
