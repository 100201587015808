import { format } from "date-fns";
import { replace } from "lodash";
import numeral from "numeral";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function formatDateTime(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    h = "" + d.getHours(),
    m = "" + d.getMinutes(),
    s = "" + d.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (h.length < 2) h = `0${h}`;
  if (m.length < 2) m = `0${m}`;
  if (s.length < 2) s = `0${s}`;

  return `${h}:${m}:${s} ${day}/${month}/${year}`;
}

export function toNested(data, pid = null) {
  return data.reduce((r, e) => {
    if (e.parent_id === pid) {
      const object = { ...e };
      const children = toNested(data, e.id.toString());
      if (children.length) {
        object.children = children;
      }
      r.push(object);
    }
    return r;
  }, []);
}

export function fCurrency(s) {
  if (typeof s === "undefined" || s === "") return;
  s = s.toString();
  return s.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " đ";
}

export function fShortenNumber(number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}

export const isExternalLink = (str) => {
  if (typeof str !== "string" || str.length === 0) return false;
  if (str.includes("http")) return true;
  if (str.includes("https")) return true;
  return false;
};

export const shortName = (value) => {
  if (typeof value !== "string" || value.length === 0) return "";
  if (value.length > 50) return value.substring(0, 50) + "...";
  return value;
};

// ----------------------------------------------------------------------

hljs.configure({
  languages: ["javascript", "jsx", "sh", "bash", "html", "scss", "css", "json"],
});

window.hljs = hljs;
