import { baseURL } from "../config";
import axiosClient from "./axiosClient";

const menuOpenAPI = {
  getAll() {
    const url = `${baseURL}/api/open/menus`;
    return axiosClient.get(url);
  },
};

export default menuOpenAPI;
