import PropTypes from "prop-types";
import { Avatar, Box } from "@mui/material";

//-----------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

//-----------------------------------------------------------------

export default function Logo({ sx, props, width, height }) {
  return (
    <Box
      sx={{ width: 100, height: 100, ...sx }}
      {...props}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Avatar
        src="/static/logo/logo.png"
        sx={{ width: width, height: height }}
      />
    </Box>
  );
}
