import NavbarMobie from "./NavbarMobile";
import MHidden from "../../components/MHidden";
import NavbarDesktop from "./NavbarDesktop";
import { useEffect, useState } from "react";
import { toNested } from "../../_helper";
import menuOpenAPI from "../../API/menuOpenAPI";

//-----------------------------------------------------------------
export default function MainNavbar() {
  // eslint-disable-next-line no-unused-vars
  const [menuList, setMenuList] = useState([]);
  useEffect(() => {
    (async () => {
      const _res = await menuOpenAPI.getAll();
      setMenuList(_res.data);
    })();
  }, []);
  return (
    <>
      <MHidden width="mdDown">
        <NavbarDesktop menuConfig={toNested(menuList)} />
      </MHidden>

      <MHidden width="mdUp">
        <NavbarMobie menuConfig={menuList} />
      </MHidden>
    </>
  );
}
