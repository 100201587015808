import { Icon } from "@iconify/react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListSubheader,
  Popover,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import arrowIosUpwardFill from "@iconify/icons-eva/arrow-ios-upward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import { isExternalLink } from "../../_helper";
import { PATH_PAGE } from "../../routers/paths";

//-----------------------------------------------------------------

const NumberPhone = ({ phone }) => {
  return (
    <Stack direction="row" display="flex" alignItems="center">
      <IconButton>
        <Icon icon="material-symbols:phone-in-talk" />
      </IconButton>
      <Typography color="GrayText">{phone}</Typography>
    </Stack>
  );
};

const ImgStyle = styled("img")(({ theme }) => ({
  width: "13rem",
  height: "13rem",
  [theme.breakpoints.down("md")]: {
    width: "10rem",
    height: "10rem",
  },
}));

const TextStyle = styled(Link)(({ theme }) => ({
  fontSize: "1rem",
  [theme.breakpoints.down("md")]: {
    fontSize: ".625rem",
  },
  textTransform: "uppercase",
  textDecoration: "none",
  color: "#000000",
  fontWeight: "bold",
  padding: "0 .5rem",
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shortest,
  }),
  "&:hover": {
    opacity: 0.68,
    color: "#FF9B00",
  },
}));

const MenuDesktopItem = ({
  item,
  pathname,
  isOpen,
  setChildren,
  onOpen,
  onClose,
}) => {
  const { value, name, children } = item;
  return (
    <Box display="flex" alignItems="center">
      {!isExternalLink(value) && (
        <TextStyle component={RouterLink} to={value} sx={{fontSize: '16px'}}>
          {name}
        </TextStyle>
      )}

      {isExternalLink(value) && (
        <TextStyle component="a" href={value} target="_blank">
          {name}
        </TextStyle>
      )}

      {!!children && (
        <Icon
          style={{ cursor: "pointer" }}
          icon={isOpen ? arrowIosUpwardFill : arrowIosDownwardFill}
          size={25}
          onClick={() => {
            onOpen();
            setChildren(children);
          }}
        />
      )}
    </Box>
  );
};

//-----------------------------------------------------------------
export default function NavbarDesktop({ menuConfig }) {
  const { pathname } = useLocation();
  const [children, setChildren] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);

  const closeHoverMenu = () => {
    setMenuDropDownOpen(false);
  };

  const openHoverMenu = () => {
    setMenuDropDownOpen(true);
  };

  const widthMenu = (length) => {
    if (length <= 1) return "960px";
    if (length === 2) return "704px";
    if (length === 3) return "448px";
    if (length === 4) return "320px";
    if (length === 5) return "192px";
    if (length === 6) return "192px";
    return null;
  };

  return (
    <>
      <Box
        sx={{
          boxShadow:
            "0 0 2px 0 rgba(145, 158, 171, 0.24),0 16px 32px -4px rgba(145, 158, 171, 0.24)",
        }}
      >
        <Box>
          <Stack direction="row" spacing={1}>
            <NumberPhone phone="02933 946 668" />
            <NumberPhone phone="0939 246 998" />
          </Stack>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          sx={{ my: "-3rem" }}
          component={RouterLink}
          to="/"
        >
          <ImgStyle src="/static/logo/logo.png" alt="logo" />
          <Box flexDirection={1} />
        </Box>

        <Box>
          <Divider
            sx={{
              width: "80%",
              margin: "0 auto",
              borderBottomWidth: ".1rem",
            }}
          />
          <Box p={2}>
            <Stack
              direction="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              {menuConfig?.map((item) => (
                <MenuDesktopItem
                  key={item.id}
                  item={item}
                  pathname={pathname}
                  onOpen={openHoverMenu}
                  onClose={closeHoverMenu}
                  setChildren={setChildren}
                />
              ))}

              <Popover
                open={isMenuDropDownOpen}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 220, left: 0 }}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={closeHoverMenu}
                PaperProps={{
                  sx: {
                    px: 3,
                    pt: 5,
                    pb: 3,
                    right: 16,
                    margin: "auto",
                    maxWidth: 1280,
                    borderRadius: 2,
                    boxShadow: (theme) => theme.customShadows.z24,
                  },
                }}
              >
                <Grid container spacing={3}>
                  {children.length > 0 &&
                    children?.map((list) => {
                      const { id, name, value, children } = list;
                      return (
                        <Grid key={id} item xs={12} md={2}>
                          <List disablePadding>
                            <ListSubheader
                              disableSticky
                              disableGutters
                              to={value}
                              component={RouterLink}
                              sx={{
                                display: "flex",
                                lineHeight: "unset",
                                alignItems: "center",
                                color: "text.primary",
                                typography: "overline",
                                textDecoration: "none",
                                fontSize: ".8rem",
                              }}
                            >
                              {name}
                            </ListSubheader>
                            {!!children &&
                              children.map((item) => {
                                return (
                                  <ListItem
                                    key={item.id}
                                    to={item.value}
                                    component={RouterLink}
                                    underline="none"
                                    sx={{
                                      p: 0,
                                      mt: 3,
                                      typography: "body2",
                                      color: "text.secondary",
                                      transition: (theme) =>
                                        theme.transitions.create("color"),
                                      "&:hover": { color: "text.primary" },
                                    }}
                                  >
                                    {item.name}
                                  </ListItem>
                                );
                              })}
                          </List>
                        </Grid>
                      );
                    })}
                </Grid>
              </Popover>

              <Box
                position="relative"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <input
                  style={{
                    borderRadius: "5rem",
                    height: "2.25rem",
                    padding: "0 10px 0 10px",
                    outline: "none",
                    border: "2px solid rgba(145, 158, 171, 0.24)",
                    width: widthMenu(menuConfig.length),

                  }}
                  placeholder="Tìm kiếm ..."
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter")
                      navigate(`${PATH_PAGE.product_filter}?name=${search}`);
                  }}
                />
                <IconButton
                  onClick={() => {
                    navigate(`${PATH_PAGE.product_filter}?name=${search}`);
                  }}
                  size="small"
                  sx={{
                    backgroundColor: "#000",
                    "&:hover": { backgroundColor: "#000" },
                    position: "absolute",
                    right: 4,
                  }}
                >
                  <Icon icon="material-symbols:search" color="#fff" />
                </IconButton>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
}
