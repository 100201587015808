import { Avatar, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import {  MESSENGER, ZALO } from "../../config";
import Footer from "./Footer";
import MainNavbar from "./MainNavbar";

export default function MainLayout() {
  return (
    <>
      <MainNavbar />
      <div>
        <Outlet />
      </div>
      <Footer />
      <Stack
        direction="column"
        spacing={2}
        sx={{ position: "fixed", zIndex: 999, bottom: 10, right: 10 }}
      >
        <Avatar
          component="a"
          href={MESSENGER}
          target="_blank"
          sx={{ width: 50, height: 50 }}
          src="/static/icons/facebook-messenger.svg"
        />
        <Avatar
          sx={{ width: 50, height: 50 }}
          src="/static/icons/zalo.png"
          component="a"
          href={ZALO}
          target="_blank"
        />
      </Stack>
    </>
  );
}
